import './App.css';
import React, { useState, useEffect } from 'react';
import DocumentMeta from 'react-document-meta';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import HomeIcon from '@material-ui/icons/Home';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import InputMask from 'react-input-mask';

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [expired, setExpired] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [client, setClient] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#e6e6e6');
  const [bannerImage, setBannerImage] = useState('');
  const [businessName, setBusinessName] = useState('Checkin Online Portal');
  const [businessPhone, setBusinessPhone] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(undefined);

  const [checkinToken, setCheckinToken] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('token')) {
      axios.post('https://app.clientdiary.com/methods/getBusinessDetailsOnlineCheckin', [urlParams.get('token')])
      .then(function (response) {
        if (!response.data.available) {
          setExpired(true);
          setLoading(false);
        } else {
          setCheckinToken(urlParams.get('token'));
          setClient(response.data.client);
          setBackgroundImage(response.data.backgroundImage === undefined ? '' : response.data.backgroundImage);
          setBackgroundColor(response.data.backgroundColor === undefined ? '#e6e6e6' : response.data.backgroundColor);
          setBannerImage(response.data.bannerImage === undefined ? '' : response.data.bannerImage);
          setBusinessName(response.data.businessName);
          setBusinessPhone(response.data.businessPhone);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    } else {
      setExpired(true);
      setLoading(false);
    }
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  }

  const handleExitedSnackbar = () => {
    setSnackbarMessage(undefined);
  };

  const handleCheckin = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (client.firstname.trim() === '') {
      setSnackbarOpen(true);
      setSnackbarMessage({
        severity: 'error',
        message: 'First name is required'
      });
    } else if (client.lastname.trim() === '') {
      setSnackbarOpen(true);
      setSnackbarMessage({
        severity: 'error',
        message: 'Last name is required'
      });
    } else if (client.phone.trim() === '') {
      setSnackbarOpen(true);
      setSnackbarMessage({
        severity: 'error',
        message: 'Mobile number is required'
      });
    } else if (!re.test(client.email)) {
      setSnackbarOpen(true);
      setSnackbarMessage({
        severity: 'error',
        message: 'Email is not valid'
      });
    } else {
      setSubmitting(true);

      axios.post('https://app.clientdiary.com/methods/checkinOnline', [client, checkinToken])
      .then(function (response) {
        setSubmitting(false);
        setSubmitted(true);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }

  const getComputedStyle = () => {
    let mainStyle = {
      flex: 1
    };

    let backgroundImageStyle = '';
    if (backgroundImage !== '') {
      backgroundImageStyle = 'url(' + backgroundImage + ')';
    }

    if (backgroundImage.includes('Default')) {
      mainStyle.backgroundColor = backgroundColor;
    } else if (backgroundImage !== '') {
      mainStyle.backgroundImage = backgroundImageStyle;
      mainStyle.backgroundRepeat = 'repeat';
    }

    return mainStyle
  }

  const getAuthor = () => {
    let author = '';
    
    if (backgroundImage !== '') {
      if (backgroundImage.includes('BSGStudio')) {
        author = 'BSGStudio';
      } else if (backgroundImage.includes('freedesignfile')) {
        author = 'freedesignfile';
      } else if (backgroundImage.includes('peecheey')) {
        author = 'peecheey';
      } else if (backgroundImage.includes('webdesignhot')) {
        author = 'webdesignhot';
      }
    }

    return author
  }

  const renderContent = () => {
    if (expired) {
      return (
        <Card variant="outlined" style={{ margin: 15 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>Checkin token has expired or does not exist.</Typography>
            <Typography variant="h6" gutterBottom>What should I do?</Typography>
            <Typography variant="body1" gutterBottom>♦ Please ask us to send you another link to checkin and update details.</Typography>
            <br />
            <Typography variant="body2">Thank you,</Typography>
            <Typography variant="body2"><strong><i>{businessName}</i></strong></Typography>
            <Typography variant="body2" gutterBottom><strong><i>{businessPhone}</i></strong></Typography>
            <br />
            <Typography variant="subtitle2"><i><b>Note: </b>Link is only valid for 30 minutes and can only be used once.</i></Typography>
          </CardContent>
        </Card>
      )
    } else {
      if (submitted) {
        return (
          <Card variant="outlined" style={{ margin: 15 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>Thanks {client.firstname} for using checkin online.</Typography>
              <Typography variant="h6" gutterBottom>What's next?</Typography>
              <Typography variant="body1" gutterBottom>♦ There is no next. Your info has been updated in our system 😉</Typography>
              <br />
              <Typography variant="body2">Thank you,</Typography>
              <Typography variant="body2"><strong><i>{businessName}</i></strong></Typography>
              <Typography variant="body2" gutterBottom><strong><i>{businessPhone}</i></strong></Typography>
              <br />
              <Typography variant="subtitle2"><i>You can now safely close this page.</i></Typography>
            </CardContent>
          </Card>
        )
      } else {
        return (
          <Card variant="outlined" style={{ margin: 15, maxWidth: 400 }}>
            <CardHeader title="Checkin - Client Details" />
              <CardContent>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>First Name *</InputLabel>
                  <OutlinedInput
                    value={client.firstname}
                    onChange={(e) => setClient({ ...client, firstname: e.target.value })}
                    labelWidth={90}
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Last Name *</InputLabel>
                  <OutlinedInput
                    value={client.lastname}
                    onChange={(e) => setClient({ ...client, lastname: e.target.value })}
                    labelWidth={90}
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup aria-label="gender" name="gender" value={client.gender} onChange={(e) => setClient({ ...client, gender: e.target.value })}>
                    <FormControlLabel value="female" control={<Radio color="primary" />} label="Female" />
                    <FormControlLabel value="male" control={<Radio color="primary" />} label="Male" />
                    <FormControlLabel value="unknown" control={<Radio color="primary" />} label="Not Recorded" />
                  </RadioGroup>
                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Date of Birth</InputLabel>
                  <InputMask
                    mask="99/99/0999"
                    formatChars={{
                      "0": "[1-9]",
                      "9": "[0-9]"
                    }}
                    value={client.dateOfBirth}
                    onChange={(e) => setClient({ ...client, dateOfBirth: e.target.value })}
                  >
                    {(inputProps) =>
                      <OutlinedInput
                        {...inputProps}
                        placeholder="DD/MM/YYYY"
                        labelWidth={90}
                        startAdornment={
                          <InputAdornment position="start">
                            <CakeIcon />
                          </InputAdornment>
                        }
                      />
                    }
                  </InputMask>

                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Mobile Phone *</InputLabel>
                  <OutlinedInput
                    value={client.phone}
                    onChange={(e) => setClient({ ...client, phone: e.target.value })}
                    labelWidth={115}
                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneIphoneIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Home Phone</InputLabel>
                  <OutlinedInput
                    value={client.homePhone}
                    onChange={(e) => setClient({ ...client, homePhone: e.target.value })}
                    labelWidth={100}
                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Work Phone</InputLabel>
                  <OutlinedInput
                    value={client.workPhone}
                    onChange={(e) => setClient({ ...client, workPhone: e.target.value })}
                    labelWidth={100}
                    startAdornment={
                      <InputAdornment position="start">
                        <BusinessIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Email address</InputLabel>
                  <OutlinedInput
                    value={client.email}
                    onChange={(e) => setClient({ ...client, email: e.target.value })}
                    labelWidth={105}
                    startAdornment={
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Street address</InputLabel>
                  <OutlinedInput
                    value={client.streetAddress}
                    onChange={(e) => setClient({ ...client, streetAddress: e.target.value })}
                    labelWidth={105}
                    startAdornment={
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Town/Suburb</InputLabel>
                  <OutlinedInput
                    value={client.townSuburb}
                    onChange={(e) => setClient({ ...client, townSuburb: e.target.value })}
                    labelWidth={100}
                    startAdornment={
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" margin="normal" style={{ width: '45%' }}>
                  <InputLabel>State</InputLabel>
                  <OutlinedInput
                    value={client.state}
                    onChange={(e) => setClient({ ...client, state: e.target.value })}
                    labelWidth={50}
                    startAdornment={
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl variant="outlined" margin="normal" style={{ width: '45%', float: 'right' }}>
                  <InputLabel>Postcode</InputLabel>
                  <OutlinedInput
                    value={client.postcode}
                    onChange={(e) => setClient({ ...client, postcode: e.target.value })}
                    labelWidth={75}
                    startAdornment={
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" onClick={handleCheckin} disabled={submitting ? true : false}>
                  {submitting ? <CircularProgress /> : "Check in"}
                </Button>
              </CardActions>
          </Card>
        )
      }
    }
  }

  return (
    <DocumentMeta {...{ title: businessName === '' ? 'Client Diary' : businessName }}>
      { loading ?
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress />
        </div>
        :
        <div className={classes.root}>
          <div style={getComputedStyle()}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                  {businessName}
                </Typography>
              </Toolbar>
            </AppBar>
            <div className="container">
              <Grid container direction="column" alignItems="center">
                { bannerImage === '' ?
                  null
                  :
                  <Grid item>
                    <img src={bannerImage} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="banner" />
                  </Grid>
                }
                <Grid item>
                  {renderContent()}
                </Grid>
              </Grid>
            </div>
          </div>
          <div style={{ backgroundColor: 'rgb(56, 67, 76)' }}>
            <div className="container">
              <div className="center-xs" style={{ margin: 10 }}>
                <div style={{ color: 'white', fontSize: 13 }}>
                  Checkin Online Portal powered by <a href="https://clientdiary.com" target="_blank" rel="noreferrer" style={{ color: 'white' }}><b><i>Client Diary</i></b></a>
                </div>
                <div style={{ color: 'white', fontSize: 11, display: (getAuthor() === '' ? 'none' : '') }}>
                  Background image by {getAuthor()} from all-free-download.com
                </div>
              </div>
            </div>
          </div>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbar}
            onExited={handleExitedSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity={snackbarMessage ? snackbarMessage.severity : 'success'}>
              {snackbarMessage ? snackbarMessage.message : undefined}
            </Alert>
          </Snackbar>
        </div>
      }
    </DocumentMeta>
  );
}

export default App;
